import LeaveOfAbsence from "../components/Demo/LeaveOfAbsence.vue";
import Offboarding from "../components/Demo/Offboarding.vue";
import BankingApp from "../components/Demo/BankingApp.vue";
import MobileSite from "../components/Demo/MobileSite";


export const routes = [
    {
      path: '/',
      redirect: { name: 'Default'},
    },
    {
      path: '/loa',
      name: 'Default',
      component: LeaveOfAbsence,
      meta: {
        title: 'Leave of Absence Portal'
      }
    },
    {
      path: '/offboarding',
      name: 'Offboarding',
      component: Offboarding,
      meta: {
        title: 'Offboarding'
      }
    },
    {
      path: '/bankingapp',
      name: 'BankingApp',
      component: BankingApp,
      meta: {
        title: 'Banking App'
      }
    },
    {
      path: '/mobilesite',
      name: 'MobileSite',
      component: MobileSite,
      meta: {
        title: 'Mobile Demo'
      }
    },

  ];