<template>
  <div class="base-font">
    <div class="homePage" v-if="page >= 0">
      <Header showForPage="benefit_info" />
      <div class="breadcrumb-container base-margin" v-if="page > 1">
        <Breadcrumb :pages="activeBreadcrumb" :activePageNumber="activeBreadcrumbPageNumber" :allowFuturePageClick="true"
                    :showFuturePages="showFutureBreadcrumbPages" :onBreadcrumbClick="onBreadcrumbClick" :language="{ name: 'English', code: 'en' }"></Breadcrumb>
      </div>
      <div class="content" :class="page === 4 ? '' : 'padding'">
        <FrontPage v-if="page === 0" :onClickStart="clickImReady"></FrontPage>
        <div v-if="page === 1" style="display:flex;">
          <div style="flex: 1 1 60%; padding-right: 20px">
            <Survey buttonLocation="bottom"  :definedBreadCrumbs="preSurveyBreadcrumbPages"
              :showNextButtonInFooter="finalPage" :code="surveyCode"
              :onQuestionAnswered="onQuestionAnswered" :onBreadcrumbClick="onBreadcrumbClick"
              :onPageDisplay="onPageDisplay" :onEndOfSurvey="onEndOfSurvey"></Survey>
          </div>
          <div style="flex: 1 1 40%; padding: 0; box-sizing: border-box; padding-top: 3.15em" v-if="!finalPage">
            <img :src="surveyImageUrl" style="width: 100%">
          </div>
        </div>
        <SummaryPage v-if="page === 2 && !isManager"
                    :tiles="summaryTiles"
                    :onTileClick="onSummaryTileClick" :backToSurvey="backToSurvey"></SummaryPage>
        <Accordion v-if="page === 3 || (page === 2 && isManager)"  :key="componentKey"
                    :accordionCode="activeAccordionCode" 
                    :replacements="replacements" 
                    :onAccordionEnd="accordionEndFunction" 
                    :backToSurvey="backToSurvey"
                    :returnToSummary="returnToSummary"></Accordion>
        <SinglePageAccordion v-if="page === 4"
                              accordionCode="IKEA_RESOURCES"
                              :displayTitle="false"
                              :hasDisplayLogic="true"
                              :logicParameters="piniaSurvey.getAttributes"></SinglePageAccordion>
      </div>
      <div class="footer_container noprint" v-if="page === 0">
            <div class="footer">
                <div>© 2002-{{year}}</div><div>The Jellyvision Lab, Inc.</div><div>All rights reserved.</div>
            </div>
        </div>
    </div>
  </div>
</template>

<script> 
require('@/assets/css/client/Ikea/ikea_variables.scss');
import FrontPage from '@/components/Ikea/FrontPage';
import Survey from '@/components/shared/Survey/Survey3';
import SummaryPage from '@/components/Ikea/SummaryPage';
import Accordion from './Accordion.vue';
import SinglePageAccordion from '@/components/shared/Accordion';
import Breadcrumb from '@/components/shared/Breadcrumb.vue';
import { data } from '@/shared';
import { useAuth0 } from '@auth0/auth0-vue';
import { useLoaStore } from "@/stores/loa";
import Header from '@/components/Ikea/Header';
import { translation } from '@/shared/translation';

export default {
  name: "BenefitInfo",
  components: { FrontPage, Survey, Accordion, SummaryPage, Breadcrumb, SinglePageAccordion, Header },
  setup() {
      const piniaSurvey = useLoaStore();

      const formatterWithDigits = new Intl.NumberFormat('en-US', {
          style: 'currency',
          currency: 'USD',
          minimumFractionDigits: 2
      });

      const formatterWithoutDigits = new Intl.NumberFormat('en-US', {
          style: 'currency',
          currency: 'USD',
          minimumFractionDigits: 0
      });


      return { formatterWithDigits, formatterWithoutDigits, piniaSurvey };
  },
  data() {
    return {
      page: 0,
      logoSrc: '',
      replacements: null,
      returnToSummary: false,
      activeAccordionCode: null,
      activeBreadcrumbPageNumber: 3,
      surveyCode: null,
      user: null,
      storageBaseUrl: '',
      finalPage: false,
      pay: 0,
      location: '',
      tackBonus: 0,
      ikeaBonus: 0,
      componentKey: 0,
      isManager: false,
      ptoPlusHolidayHours: '',
      gettingApiData: false,
      employeeInfo: null,
      isDebug: false,
      // Placeholder data that will come from Survey results
      preSurveyBreadcrumbPages: [
        { titleJson: [{language:'English', text: 'Home'}], number: 1 }
      ],
      postSurveyBreadcrumbPages: [
        { titleJson: [{language:'English', text: 'Home'}], number: 1 },
        { titleJson: [{language:'English', text: '...'}], number: 2 },
        { titleJson: [{language:'English', text: 'Overview'}], number: 3 },
        { titleJson: [{language:'English', text: 'Pay'}], number: 4 },
        { titleJson: [{language:'English', text: 'Incentives'}], number: 5 },
        { titleJson: [{language:'English', text: 'Hidden Paycheck'}], number: 6 },
        { titleJson: [{language:'English', text: 'Location'}], number: 7 },
        { titleJson: [{language:'English', text: 'PTO'}], number: 8 },
        { titleJson: [{language:'English', text: 'Career'}], number: 9 },
        { titleJson: [{language:'English', text: 'Resources'}], number: 10 },
      ],
      managerBreadCrumbPages: [
        { titleJson: [{language:'English', text: 'Home'}], number: 1 },
        { titleJson: [{language:'English', text: '...'}], number: 2 },
        { titleJson: [{language:'English', text: 'Intro'}], number: 3 },
        { titleJson: [{language:'English', text: 'Our Approach'}], number: 4 },
        { titleJson: [{language:'English', text: 'Comp'}], number: 5 },
        { titleJson: [{language:'English', text: 'Incentives'}], number: 6 },
        { titleJson: [{language:'English', text: 'Location'}], number: 7 },
        { titleJson: [{language:'English', text: 'PTO'}], number: 8 },
        { titleJson: [{language:'English', text: 'Career'}], number: 9 },
        { titleJson: [{language:'English', text: 'Resources'}], number: 10 }
      ],
      managerAccordionCodes: [
        'IKEA_MANAGER_INTRO',
        'IKEA_MANAGER_APPROACH',
        'IKEA_MANAGER_COMP',
        'IKEA_MANAGER_INCENT',
        'IKEA_MANAGER_GEOTIERS',
        'IKEA_MANAGER_PTO',
        'IKEA_MANAGER_CAREER',
        'IKEA_MANAGER_ROLE',
        'IKEA_MANAGER_LATERAL',
        'IKEA_MANAGER_DEMOT',
        'IKEA_MANAGER_INTERIM',
        'IKEA_MANAGER_REHIRE',
      ]
    }
  },
  computed: {
    attributes() {
      return this.piniaSurvey.getAttributes;
    },
    summaryTiles() {
      return [
        {
            title: 'Your pay',
            subtitle: this.pay,
            text: 'This is your current base pay.',
            accordionCode: 'IKEA_PAY',
            backgroundColor: '#3CBF00'
        },
        {
            title: '<em>Tack!</em>',
            subtitle: Number(this.tackBonus).toLocaleString("en-US", { style: 'currency', currency: 'USD', }),
            text: 'Last year’s amount. This will change every year.',
            accordionCode: 'IKEA_INCENTIVES',
            backgroundColor: '#378226'
        },
        {
            title: 'One IKEA Bonus',
            subtitle: this.ikeaBonus.toLocaleString("en-US", { style: 'currency', currency: 'USD', }),
            text: 'Your bonus payout might be more or less.',
            accordionCode: 'IKEA_INCENTIVES',
            backgroundColor: '#003663'
        },
        {
            title: 'Your "hidden paycheck"',
            subtitle: 'Varies',
            text: 'Don’t forget about those benefits!',
            accordionCode: 'IKEA_HIDDEN_PAY',
            backgroundColor: '#004884'
        },
        {
            title: 'Location',
            subtitle: this.location,
            text: 'Learn how location affects your pay.',
            accordionCode: 'IKEA_LOCATION',
            backgroundColor: '#0070C0'
        },
        {
            title: 'Paid time off and holidays',
            subtitle: this.ptoPlusHolidayHours + ' Days',
            text: 'These are the days you could accrue this year.',
            accordionCode: 'IKEA_PTO',
            backgroundColor: '#7030A0'
        },
        {
            title: 'Growth',
            subtitle: 'Varies',
            text: 'Assemble the career of your dreams at IKEA!',
            accordionCode: 'IKEA_DREAM_CAREER',
            backgroundColor: '#92278F'
        }
      ]     
    },
    year() { 
      return new Date().getFullYear();
    },
    accordionEndFunction() {
      return this.isManager ? this.onManagerAccordionEnd : this.onAccordionEnd;
    },
    activeBreadcrumb() {
      switch(this.page) {
        case 0:
          return null;
        case 1:
          return this.preSurveyBreadcrumbPages;
        case 2:
        case 3:
        case 4:
          return this.isManager ? this.managerBreadCrumbPages : this.postSurveyBreadcrumbPages;
        default:
          return null;
      }
    },
    showFutureBreadcrumbPages() {
      return this.page !== 1;
    },
    surveyImageUrl() {
      return this.storageBaseUrl + "/Ikea/Images/PH170213.jpg";
    },
  },
  beforeUnmount() {
    window.removeEventListener("beforeunload", this.handleBeforeUnload);
  },
  async mounted() {
    window.addEventListener("beforeunload", this.handleBeforeUnload);

    document.addEventListener('click', function(event) {
        const target = event.target;
        
        if (target.tagName === 'A' && target.href) {
          var logObject = {
              url: target.href
          };

          data.postActivityLog({          
            clientCode: 'IKEA',
            logActivityTypeCode: 'CLICK_LINK',
            data: JSON.stringify(logObject),
          });
        }
    });

    try {
      const auth0 = useAuth0();
      this.user = auth0.user;

      let uri = window.location.search.substring(1); 
      let params = new URLSearchParams(uri);
      this.isDebug = (params.get("debug"))

      if(this.isDebug)
        console.log(this.user);

      var logObject = {
            url: window.location.href,
            user: this.user
        };

      data.postActivityLog({          
        clientCode: 'IKEA',
        logActivityTypeCode: 'LANDING_PAGE',
        data: JSON.stringify(logObject),
      });

      this.getApiData();
    }
    catch{
      this.user = null;
      console.log("user error");
      return;
    }
    
    this.storageBaseUrl = await data.getEnvironmentVariable(data.enumEnvironmentVariableFields.fileStorageUrl);

    this.getClientConstants();

    // Get logo, but don't break if not there
    let logo;
    const logoMpId = await data.getClientLogoAssetID();
    if(logoMpId)
        logo = await data.getMediaProperty(logoMpId);
    if(logo)
        this.logoSrc = logo.configuration.src;
  },
  watch: {
    activeAccordionCode() {
      if(this.isManager && this.activeAccordionCode == null) return;

      var logObject = {
            accordion: this.activeAccordionCode != null ? this.activeAccordionCode : 'IKEA_SUMMARY'
        };

      data.postActivityLog({
            logActivityTypeCode: 'DISPLAY_ACCORDION',
            data: JSON.stringify(logObject)
        }); 
    }
  },
  methods: {
    handleBeforeUnload() {
      data.postActivityLog({
            logActivityTypeCode: 'LEAVE_SITE'
        });
    },
    onQuestionAnswered: async function() {
      var ctr = 1;
      while (this.attributes["LOOKUP_INFO"] == "YES" && this.gettingApiData == true && ctr <= 30) {
        await data.Sleep(1000);

        ctr++;
      }

      if(this.attributes["LOOKUP_INFO"] == "YES" && ctr < 30 && this.employeeInfo != null)
        this.processEmployee()
    },
    getApiData: async function() {
      if(this.user.EmployeeId || this.isDebug) {
        this.gettingApiData = true;
        data.getIkeaApiInfo()
            .then(response => {this.employeeInfo = response; this.gettingApiData = false;});
      }      
    },
    processEmployee: async function() {      
      if(this.isDebug)
        console.log(this.employeeInfo);

      var lookupFields = [];

      if(this.employeeInfo.employeeType)
      {
        this.piniaSurvey.setAttribute("LOA_EMPLOYEE_TYPE", this.attributes["LOA_EMPLOYEE_TYPE_VALUE"] = this.employeeInfo.employeeType);
        lookupFields.push("_LOA_EMPLOYEE_TYPE");        

        switch(this.employeeInfo.employeeType) {
          case "HL1": 
            this.piniaSurvey.setAttribute("LOA_HOURS_PER_WEEK", "12"); 
            break;
          case "HL2": this.piniaSurvey.setAttribute("LOA_HOURS_PER_WEEK", "20"); break;
          case "HL3": this.piniaSurvey.setAttribute(["LOA_HOURS_PER_WEEK"], "34"); break;
          case "HL4": 
            this.piniaSurvey.setAttribute("LOA_HOURS_PER_WEEK", "40"); break;
        }
        if(this.attributes["LOA_HOURS_PER_WEEK"])
          lookupFields.push("_LOA_HOURS_PER_WEEK");        
      }      

      if(this.employeeInfo.isManager) {
        this.piniaSurvey.setAttribute("IS_MANAGER", this.employeeInfo.isManager ? "YES" : "NO");
        lookupFields.push("_IS_MANAGER");        
      }
      if(this.employeeInfo.startDate)
      {
        this.piniaSurvey.setAttribute("LOA_START_DATE", this.employeeInfo.startDate);
        lookupFields.push("_LOA_START_DATE");
      } 
      if(this.employeeInfo.wage) {
        this.piniaSurvey.setAttribute("PAY", this.employeeInfo.wage);
        lookupFields.push("_PAY");        
      }

      if(this.employeeInfo.oneIkeaBonusLevel) {
        this.piniaSurvey.setAttribute("ONE_IKEA_BONUS_LEVEL", this.employeeInfo.oneIkeaBonusLevel);
        lookupFields.push("_ONE_IKEA_BONUS_LEVEL");        
      }

      if(this.employeeInfo.location)
      {
        this.piniaSurvey.setAttribute("LOCAL_ASSOCIATION", this.employeeInfo.location);
        this.piniaSurvey.setAttribute("LOCAL_ASSOCIATION_VALUE", this.employeeInfo.location);
        lookupFields.push("_LOCAL_ASSOCIATION");
      }

      this.piniaSurvey.setAttribute("LOOKUP_INFO", lookupFields);
    
      if(this.isDebug)
        console.log(this.attributes);
    },
    onPageDisplay: function(survey, activePageIndex) {
      this.finalPage = survey.pages.length - 1 === activePageIndex;

      if(this.finalPage === true) {
        setTimeout(this.onEndOfSurvey, 7000);
      }
    },
    closeContactModal: function() {
      this.showContactModal = false;
    },
    clickImReady: function() {
      this.piniaSurvey.setAttributes({});
      this.page = 1;
    },
    onEndOfSurvey: function() {
      if(!this.finalPage && this.attributes["LOA_WHY_HERE"] != "MANAGER" )
        return;

      this.replacements = this.piniaSurvey.getReplacementStrings;
      this.tackBonus = this.attributes["IKEA_TACK"] != null ? this.attributes["IKEA_TACK"] : 0;
      this.isManager = this.attributes["LOA_WHY_HERE"] === 'MANAGER';
      this.ikeaBonus =  Number(this.replacements["%BONUS%"]);
      this.location = this.replacements['%LOCAL_ASSOCIATION%'] = this.attributes["LOCAL_ASSOCIATION"];
      this.pay = this.replacements['%PAY%'] = Number(this.attributes["PAY"]).toLocaleString("en-US", { style: 'currency', currency: 'USD', });
      this.ptoPlusHolidayHours = (Number(this.attributes["PTO_TIME_HRS"]) + Number(this.attributes["HOLIDAY_TIME_HRS"]))/ 8;

      switch(this.attributes["LOA_HOURLY_SALARIED"]) {
        case 'HL1':
        case 'HL2-HL4':
          this.pay = this.pay + ' ' + this.replacements['%PAY_LABEL%'];
          break;
      }

      if(this.page === 1) {        
        this.page = 2;
        this.finalPage = false;
        this.activeAccordionCode = 'IKEA_SUMMARY';
      }
      
      if(this.isManager)
        this.activeAccordionCode = this.managerAccordionCodes[0];
    },
    getClientConstants: async function() {
        const constants = await data.getClientConstants();

        //get attributes as replacement characters
        var replacementAttributes = await data.getReplacementStringAttributes();
        var replacementStrings = {};

        if(constants != null) {
            constants.forEach(constant => {
                switch(constant.key) {
                    case "LOA_SURVEY_CODE":
                        this.surveyCode = translation.getTextForLanguage(constant.valueJson, 'English');
                        break;
                    default:
                        break;
                }            

                if(replacementAttributes.find(search => search.code === constant.key))
                    replacementStrings["%" + constant.key + "%"] = translation.getTextForLanguage(constant.valueJson, 'English');
            });
        }
        this.replacements = replacementStrings;
    },
    onSummaryTileClick: function(accCode, returnToSummary) {
      this.returnToSummary = returnToSummary;
      this.activeAccordionCode = accCode;
      this.setAccordionBreadcrumb(accCode);
      this.page = 3;
    },
    setAccordionBreadcrumb: function(accCode) {
      switch(accCode) {
        case 'IKEA_PAY':
          this.activeBreadcrumbPageNumber = 4;
          break;
        case 'IKEA_INCENTIVES':
          this.activeBreadcrumbPageNumber = 5;
          break;
        case 'IKEA_HIDDEN_PAY':
          this.activeBreadcrumbPageNumber = 6;
          break;
        case 'IKEA_LOCATION':
          this.activeBreadcrumbPageNumber = 7;
          break;
        case 'IKEA_PTO':
          this.activeBreadcrumbPageNumber = 8;
          break;
        case 'IKEA_DREAM_CAREER':
        case 'IKEA_ROLE_CHANGE':
        case 'IKEA_REHIRE':
          this.activeBreadcrumbPageNumber = 9;
          break;
      }
    },
    setManagerAccordionBreadcrumb: function(accCode) {
      switch(accCode) {
        case 'IKEA_MANAGER_INTRO':
          this.activeBreadcrumbPageNumber = 3;
          break;
        case 'IKEA_MANAGER_APPROACH':
          this.activeBreadcrumbPageNumber = 4;
          break;
        case 'IKEA_MANAGER_COMP':
          this.activeBreadcrumbPageNumber = 5;
          break;
        case 'IKEA_MANAGER_INCENT':
          this.activeBreadcrumbPageNumber = 6;
          break;
        case 'IKEA_MANAGER_GEOTIERS':
          this.activeBreadcrumbPageNumber = 7;
          break;
        case 'IKEA_MANAGER_PTO':
          this.activeBreadcrumbPageNumber = 8;
          break;
        case 'IKEA_MANAGER_CAREER':
        case 'IKEA_MANAGER_ROLE':
        case 'IKEA_MANAGER_LATERAL':
        case 'IKEA_MANAGER_DEMOT':
        case 'IKEA_MANAGER_INTERIM':
        case 'IKEA_MANAGER_REHIRE':
          this.activeBreadcrumbPageNumber = 9;
          break;
      }
    },
    backToSurvey: function() {
      this.piniaSurvey.setAttributes({});
      this.piniaSurvey.setReplacementStrings(null);

      this.page = 1;
    },
    onAccordionEnd: function(isMovingForward, returnToSummary) {
      if(returnToSummary) {
        this.page = 2;
        this.activeAccordionCode = null;
        this.activeBreadcrumbPageNumber = 3;
        return;
      }
      var attributes = this.piniaSurvey.getAttributes;
         
      if(!isMovingForward) {
        const activeAccordionIndex = 
          this.summaryTiles.findIndex(tile => tile.accordionCode === this.activeAccordionCode);

        if(activeAccordionIndex === 0) {
          this.page = 2;
          this.activeAccordionCode = null;
          this.activeBreadcrumbPageNumber = 3;        
        } else {
          if(this.activeAccordionCode === 'IKEA_ROLE_CHANGE' && attributes["LOA_WHY_HERE"] !== "NEW_TO_THE_TEAM") {
            this.activeAccordionCode = "IKEA_DREAM_CAREER";
          } else if(this.activeAccordionCode === 'IKEA_REHIRE' && attributes["LOA_WHY_HERE"] === "LEAVING") {
            this.activeAccordionCode = "IKEA_DREAM_CAREER";
          } else {
            this.activeAccordionCode = this.summaryTiles[activeAccordionIndex - 1].accordionCode;
          }
          this.setAccordionBreadcrumb(this.activeAccordionCode);
          this.componentKey++;
        }
      } else {
        //we have two tiles for incentives so if we come back, we need to increment 2
         const activeAccordionIndex = 
          this.summaryTiles.findIndex(tile => tile.accordionCode === this.activeAccordionCode) + (this.activeAccordionCode === 'IKEA_INCENTIVES' ? 1 : 0);

        if(activeAccordionIndex === this.summaryTiles.length - 1)
          this.goToResources();
        else {
          if(this.activeAccordionCode === 'IKEA_DREAM_CAREER' && attributes["LOA_WHY_HERE"] !== "NEW_TO_THE_TEAM") {
            this.activeAccordionCode = "IKEA_DREAM_CAREER";
          } else if(this.activeAccordionCode === 'IKEA_ROLE_CHANGE' && attributes["LOA_WHY_HERE"] === "LEAVING") {
            this.activeAccordionCode = "IKEA_DREAM_CAREER";
          } else {
            this.activeAccordionCode = this.summaryTiles[activeAccordionIndex + 1].accordionCode;
          }
          this.setAccordionBreadcrumb(this.activeAccordionCode);
          this.componentKey++;
        }
      }
    },
    onManagerAccordionEnd: function(isMovingForward) {
      const activeAccordionIndex = this.managerAccordionCodes.indexOf(this.activeAccordionCode);

      if(!isMovingForward) {
        if(activeAccordionIndex === 0) {
          this.backToSurvey();
        } else {
          this.activeAccordionCode = this.managerAccordionCodes[activeAccordionIndex - 1];
          this.setManagerAccordionBreadcrumb(this.activeAccordionCode);
          this.componentKey++;
        }
      } else {
        if(activeAccordionIndex === this.managerAccordionCodes.length - 1)
          this.goToResources();
        else {
          this.activeAccordionCode = this.managerAccordionCodes[activeAccordionIndex + 1];
          this.setManagerAccordionBreadcrumb(this.activeAccordionCode);
          this.componentKey++;
        }
      }
    },
    goToResources: function() {
      this.activeAccordionCode = 'IKEA_RESOURCES';
      this.page = 4;
      this.activeBreadcrumbPageNumber = 10;
    },
    onBreadcrumbClick: function(pg) {
      if(this.page === 1) {
        if(pg.number === 1) {
            this.page = 0;
            this.activeAccordionCode = null;
            this.returnToSummary = false;       
        }          
      }
      else {
        this.activeBreadcrumbPageNumber = pg.number;
        var title = pg.titleJson.find(search => search.language = 'English').text;
        switch(title) {
          case 'Home':
            this.page = 0;
            this.activeAccordionCode = null;
            this.returnToSummary = false;
            break;
          case '...':
            this.page = 1;
            this.activeAccordionCode = null;
            this.returnToSummary = false;
            break;
          case 'Overview':
            this.page = 2;
            this.activeAccordionCode = null;
            this.returnToSummary = false;
            break;
          case 'Pay':
            this.page = 3;
            this.activeAccordionCode = this.summaryTiles.find(til => til.title.toLowerCase().indexOf('your pay') >= 0).accordionCode;
            this.componentKey++;
            this.returnToSummary = true;
            break;
          case 'Incentives':
            if(!this.isManager) {
              this.page = 3;
              this.activeAccordionCode = this.summaryTiles.find(til => til.title.toLowerCase().indexOf('tack') >= 0).accordionCode;
              this.returnToSummary = true;
            } else {
              this.page = 2;
              this.activeAccordionCode = 'IKEA_MANAGER_INCENT';
            }
            this.componentKey++;
            break;
          case 'Hidden Paycheck':
            this.page = 3;
            this.activeAccordionCode = this.summaryTiles.find(til => til.title.toLowerCase().indexOf('hidden paycheck') >= 0).accordionCode;
            this.componentKey++;
            this.returnToSummary = true;
            break;
          case 'Location':
            if(!this.isManager) {
              this.page = 3;
              this.activeAccordionCode = this.summaryTiles.find(til => til.title.toLowerCase().indexOf('location') >= 0).accordionCode;
              this.returnToSummary = true;
            } else {
              this.page = 2;
              this.activeAccordionCode = 'IKEA_MANAGER_GEOTIERS';
            }
            this.componentKey++;
            break;
          case 'PTO':
            if(!this.isManager) {
              this.page = 3;
              this.activeAccordionCode = this.summaryTiles.find(til => til.title.toLowerCase().indexOf('paid time off') >= 0).accordionCode;
              this.returnToSummary = true;
            } else {
              this.page = 2;
              this.activeAccordionCode = 'IKEA_MANAGER_PTO';
            }
            this.componentKey++;
            break;
          case 'Career':
            if(!this.isManager) {
              this.page = 3;
              this.activeAccordionCode = this.summaryTiles.find(til => til.title.toLowerCase().indexOf('growth') >= 0).accordionCode;
              this.returnToSummary = true;
            } else {
              this.page = 2;
              this.activeAccordionCode = 'IKEA_MANAGER_CAREER';
            }
            this.componentKey++;
            break;
          case 'Resources':
            this.goToResources();
            this.returnToSummary = false;
            break;
          case 'Intro':
            this.page = 2;
            this.activeAccordionCode = 'IKEA_MANAGER_INTRO';
            this.componentKey++;
            break;
          case 'Our Approach':
            this.page = 2;
            this.activeAccordionCode = 'IKEA_MANAGER_APPROACH';
            this.componentKey++;
            break;
          case 'Comp':
            this.page = 2;
            this.activeAccordionCode = 'IKEA_MANAGER_COMP';
            this.componentKey++;
            break;
        }
      }
    }
  }
}

</script>

<style scoped lang="scss" src="@/assets/css/client/Ikea/benefit_info.scss"></style>
<style scoped lang="scss" src="@/assets/css/shared/breadcrumb/breadcrumb_standard.scss"></style>