<template>
    <div v-if="detail_local" :class="detail_local.cssClass">
        <DisplayText v-if="detail_local.displayText && (!detail_local.mediaProperty || detail_local.mediaProperty.mediaType === 'ToolTip')" 
                    :displayText="getDetail(detail_local)"></DisplayText>
        <video-player v-if="detail_local.mediaProperty && detail_local.mediaProperty.mediaType === 'Video'" 
            :mediaPropertyID="detail_local.mediaPropertyID" :options="detail_local.mediaProperty.configuration" 
            :tracks="detail_local.mediaProperty.configuration.tracks" :ref="'video' + detail_local.id"
            :videoDescription="detail_local.mediaProperty.description"></video-player>
        <img v-if="detail_local.mediaProperty && detail_local.mediaProperty.mediaType === 'Image' && detail_local.mediaProperty.configuration.calcSrc"
            :style="imageStyle" :src="detail_local.mediaProperty.configuration.calcSrc" />
        <Checklist v-if="detail_local.mediaProperty && detail_local.mediaProperty.mediaType === 'Checklist'" :options="detail_local.mediaProperty.configuration" :client="client" :ref="'checklist' + detail_local.id" />
        <SampleLanguageBox v-if="detail_local.mediaProperty && detail_local.mediaProperty.mediaType === 'SampleLanguage'" :options="detail_local.mediaProperty.configuration" :client="client" :ref="'sample' + detail_local.id" />
        <BarChart v-if="detail_local.mediaProperty && detail_local.mediaProperty.mediaType === 'BarChart'" :options="detail_local.mediaProperty.configuration" :client="client" id="'barchar' + detail_local.id" />
        <Table v-if="detail_local.mediaProperty && detail_local.mediaProperty.mediaType === 'Table'" :options="detail_local.mediaProperty.configuration"></Table>
        <HtmlTiles v-if="detail_local.mediaProperty && detail_local.mediaProperty.mediaType === 'HtmlTiles'" :configuration="detail_local.mediaProperty.configuration"
                :onChangeTile="updateAudio" :replacements="replacements"></HtmlTiles>
    </div>
</template>

<script>
import VideoPlayer from '@/components/VideoPlayer.vue';
import BarChart from '@/components/shared/BarChart';
import Checklist from '@/components/shared/Checklist';
import SampleLanguageBox from '@/components/shared/SampleLanguageBox';
import Table from '@/components/shared/Table';
import DisplayText from '@/components/shared/DisplayText';
import HtmlTiles from '@/components/shared/MediaProperties/HtmlTiles';
import { translation } from '@/shared/translation';
import { replacementStrings } from '@/shared/replacementStrings';

import { data } from '@/shared/data';
export default {
    name: 'AccordionDetail',
    components: { VideoPlayer, Checklist, SampleLanguageBox, BarChart, Table, DisplayText, HtmlTiles }, 
    props: {
        detail: Object,
        replacements: Object,
        onUpdateAudio: Function,
        language: String
    },
    data() {
        return {
            detail_local: null,
            tooltipConstant: '<span class="tooltip-container"><span class="tooltip-slot-container">%hover-slot%<i class="fas fa-question-circle tooltip-icon"></i></span><div class="tooltip-content" :class="{hidden: !hover}">%tooltip-slot%</div></span>'
        }
    },
    async mounted() {
        this.storageBaseUrl = await data.getEnvironmentVariable(data.enumEnvironmentVariableFields.fileStorageUrl);

        this.detail_local = this.detail;

        if((!this.detail_local.mediaPropertyID || this.detail_local.mediaProperty.mediaType === 'ToolTip') && this.replacements) {
            this.detail_local.displayTextJson = replacementStrings.replaceStringsAllLanguages(this.detail_local.displayTextJson, this.replacements);
        }
        else if(this.detail_local.mediaPropertyID && this.detail_local.mediaProperty.mediaType === 'Image' && !this.detail_local.mediaProperty.configuration.calcSrc) {
            var config = this.detail_local.mediaProperty.configuration;
            if(config.isLocalPath === false) {
                var filename = this.storageBaseUrl + (config.src[0] !== '/' ? '/' : '') + config.src; 
                var ret = await data.getMediaFileProperties(filename);
                config.calcSrc =  filename + '?v=' + ret.LastModifiedTicks;
            }
            else
                config.calcSrc = config.src;
        }
    },
    computed: {
        imageStyle() {
            var config = this.detail_local.mediaProperty.configuration;
            var style = {};
            if(config.height !== '') {
                if(config.height.indexOf("%") === -1 && config.height.indexOf("px") === -1)
                    style["height"] = config.height + "px";
                else 
                    style["height"] = config.height;
            }

            if(config.width !== '') {
                if(config.width.indexOf("%") === -1 && config.width.indexOf("px") === -1)
                    style["width"] = config.width + "px";
                else 
                    style["width"] = config.width;
            }

            return style;
        }
    },
    methods: {
        updateAudio: function(newTrackInfo) {
            if(this.onUpdateAudio)
                this.onUpdateAudio (newTrackInfo);   
        },
        getDetail: function(detail) {
            var text = this.getTextForLanguage(detail.displayTextJson);
            if(detail.mediaPropertyID && detail.mediaProperty.mediaType === 'ToolTip') {
                const toolTipStart = detail.displayText.indexOf('<ToolTip');
                const middleTextStart = detail.displayText.search(/(<ToolTip>)/) + 9;
                const middleTextEnd = detail.displayText.indexOf('</ToolTip>');
                const toolTipEnd = detail.displayText.indexOf('</ToolTip>') + 10;
                var preText = detail.displayText.substr(0, toolTipStart);
                var postText = detail.displayText.slice(toolTipEnd, detail.displayText.length);
                var middleText = detail.displayText.slice(middleTextStart, middleTextEnd);

                text = preText + this.tooltipConstant.replace('%hover-slot%', middleText).replace('%tooltip-slot%', detail.mediaProperty.configuration.text) + postText;
            }

            return text;
        },
        getTextForLanguage: function(jsonField) {
            return translation.getTextForLanguage(jsonField, this.language);
        }
    }
}
</script>
