<template>
<div class="flex_across">
    <div class="homePage" v-if="page >= 0">
        <div class="header noprint">
            <div class="flex">
              <div class="client-logo">
                  <img src="/images/MATTR/logo.png" />
              </div>
              <div class="alex-logo">
                  <img src="/images/Demo/alex-logo.svg" />
              </div>
              <div class="canada-container">
                  Canada
              </div>
            </div>
            <div class="contact-hr">
              <a @click="switchLanguage('French')" :disabled="language === 'French'" :class="language === 'French' ? 'selected' : ''">Français</a>
              <a @click="switchLanguage('English')" :disabled="language === 'English'" :class="language === 'English' ? 'selected' : ''">English</a>
              <a target="_blank" v-html="getLanguageString('contactModalButtonText')" :href="configuration.contactModalCustomContactInfo"></a>
            </div>
        </div>
        <div class="title-bar noprint" :style="getSubHeaderStyle" :class="page > 0 ? '' : 'hidden'">
            <div class="title" v-html="titleBarText"></div>
        </div>
        <div class="content">
            <FrontPage v-if="page === 0" :mainText="getLanguageString('frontPageMainText')" :onClickStart="getStarted" 
                :startButtonText="startButtonText"
                :finePrintColumn1="getLanguageString('finePrintColumn1')" 
                :finePrintColumn2="getLanguageString('finePrintColumn2')" 
                :title="getLanguageString('frontPageTitle')" :subtitle="getLanguageString('frontPageSubtitle')"></FrontPage>
            <div class="survey-wrapper"  v-if="page==1">          
              <Survey :code="surveyCode"
                  :onEndOfSurvey="local_onEndOfSurvey" :additionalMergeSurveyCodes="additionalSurveyCodes"
                    :language="getLanguageObject"
                  :onGoBack="previousPage" goBackButtonText="Go Back" :startProgressBarPagingAt="1" :totalPages="9">
              </Survey>
            </div>
            <div v-if="page === 2 && activeInfoPageConfig">
              <AccordionSectionView :accordionCode="activeInfoPageConfig.accordionCode" :hasDisplayLogic="true" maxSectionHeight="600"
                  :replacements="replacements" :logicParameters="piniaSurvey.getAttributes" :backToTopText="backToTopText"
                  :jumpToText="jumpToText" :additionalAccordionCodes="additionalAccordionCodes" :language="language"
                  :readMoreText="readMoreText" :readLessText="readLessText"></AccordionSectionView>
              <div class="start-over-button">
                <button @click="StartOver">{{ startOverText }}</button>
              </div>
            </div>
        </div>
        <div class="footer-container noprint" >
            <div class="copyright">
                {{ copyrightText }}
            </div>
            <div class="privacy-policy"><a href="https://www.jellyvision.com/privacy-policy/" target="_blank">{{ privacyText }}</a></div>
        </div>
        <FeedbackModal :questions="language === 'English' ? feedbackQuestions : feedbackQuestionsFrench" :thankYouMessage="thankYouMessage"
                        :openButtonText="openFeedbackText" :CommentsQuestionText="CommentsQuestionText" :previousButtonText="previousButtonText"
                        :submitFeedbackButtonText="submitFeedbackButtonText"></FeedbackModal>
    </div>
</div>
</template>
<script>
require('@/assets/css/client/MATTR/variables.scss');
import FrontPage from "@/components/MATTR/FrontPage";
import Survey from "@/components/shared/Survey/Survey3";
import AccordionSectionView  from '@/components/shared/AccordionSectionView';
import { useLoaStore } from "@/stores/loa";
import FeedbackModal from "@/components/shared/FeedbackModal";
import { data } from '@/shared';
import { translation } from '@/shared/translation';
require('@/assets/css/shared/feedback/feedback_variables_standard.scss');

export default {
  components: { FrontPage, Survey, AccordionSectionView,  FeedbackModal},
  name: "Default",
  computed: {
      year() { 
        return new Date().getFullYear();
      },
      titleBarText() {
        if(this.page === 1) 
            return this.language === 'English' ? "Let’s get personal(ized)" : 'Personnalisons ça';
        else if(this.page === 2) 
            return this.language === 'English' ? "Your recommendation is here!" : 'Votre recommandation est ici !';
        else return "";
      },
      getSubHeaderStyle() {
        if(this.page === 3 && this.activeInfoPageConfig)
        {
            if(this.activeInfoPageConfig.sectionTitleColor)
            {
                return "background-color:" + this.activeInfoPageConfig.sectionTitleColor;
            }
        }

        return "";
      },
      subHeaderClasses () {
        var classes = "";
        
        if(this.page === 0)
            classes = classes + " subheader_with_text";
        else 
            classes = classes + " subheader_empty";

        if(this.page !== 3) 
            classes = classes + " subheader_blue";

        return classes;
    },
    contactModalButtonText() {
        return this.configuration.contactModalButtonText ?? 'Contact Your Leave Team';
    },
    copyrightText() {
        return `© 2002-${this.year} The Jellyvision Lab, Inc. ${this.language === 'English' ? 'All rights reserved.' : 'Tous droits réservés.'}`
    },
    privacyText() {
        return this.language === 'English' ? 'Privacy Policy' : 'Politique de confidentialité';
    },
    thankYouMessage() {
        return this.language === 'English' ? 'Thanks for helping!' : 'Merci pour votre aide !';
    },
    startOverText() {
        return this.language === 'English' ? 'Start Over' : 'Recommencer';
    },
    backToTopText() {
        return this.language === 'English' ? 'Back to Top' : 'Retour en haut';
    },
    jumpToText() {
        return this.language === 'English' ? 'JUMP TO' : 'SAUTER À';
    },
    getLanguageObject() {
        return this.language === 'English' ? {name: 'English', code: 'en'} : {name: 'French', code: 'fr'};
    },
    startButtonText() {
        return this.language === 'English' ? "Let's get started" : 'Commençons';
    },
    readMoreText() {
        return this.language === 'English' ? "Read more" : 'Lire plus';
    },
    readLessText() {
        return this.language === 'English' ? "Read less" : 'Lire moins';
    },
    openFeedbackText() {
        return this.language === 'English' ? 'Feedback' : 'Réaction';
    },
    CommentsQuestionText() {
        return this.language === 'English' ? "Any other feedback to help us improve this page?" : "D'autres commentaires pour nous aider à améliorer cette page ?";
    },
    submitFeedbackButtonText() {
        return this.language === 'English' ? "SEND MY FEEDBACK" : "ENVOYER MON COMMENTAIRE";
    },
    previousButtonText() {
        return this.language === 'English' ? '<i class="fa-solid fa-chevron-left"></i> Previous' : '<i class="fa-solid fa-chevron-left"></i> Précédent';
    }
  },
  data() {
    return {
      page: -1,
      i: 0,
      showModal: false,
      surveyCode: null,
      activeInfoPageConfig: null,
      activeRedirectText: null,
      additionalSurveyCodes: ['MATTR_FRENCH'],
      additionalAccordionCodes: ['MATTR_FRENCH'],
      replacements: {},
      configuration: {
        english: {},
        french: {}
      },
      language: 'English',
      feedbackQuestions: [
        {   question:"How helpful has this experience been?",
            subject: "HOW_HELPFUL",
            sequence: 1,
            icons:[
                {score: 1, class: 'far fa-grimace', text:'Not at all helpful'},
                {score: 2, class: 'far fa-frown', text: 'Not very helpful'},
                {score: 3, class: 'far fa-meh', text: "Somewhat helpful"},
                {score: 4, class: 'far fa-smile', text: 'Very helpful'},
                {score: 5, class: 'far fa-grin-stars', text: 'Extremely helpful'}
            ]
        },
        {  question:"Do you feel like you have a better understanding of how your benefits work now?",
            sequence: 2,
            subject: "BETTER_UNDRSTNDG",
            icons:[
                {score: 1, class: 'far fa-grimace', text:'No, definitely not'},
                {score: 2, class: 'far fa-frown', text: 'No, not really'},
                {score: 3, class: 'far fa-meh', text: "Maybe"},
                {score: 4, class: 'far fa-smile', text: 'Yes, a little'},
                {score: 5, class: 'far fa-grin-stars', text: 'Yes, definitely'}
            ]
        }
      ],
      feedbackQuestionsFrench: [
        {   question:"Cette expérience vous a-t-elle été utile ?",
            subject: "HOW_HELPFUL",
            sequence: 1,
            icons:[
                {score: 1, class: 'far fa-grimace', text:'Pas du tout utile'},
                {score: 2, class: 'far fa-frown', text: 'Pas très utile'},
                {score: 3, class: 'far fa-meh', text: "Quelque peu utile"},
                {score: 4, class: 'far fa-smile', text: 'Très utile'},
                {score: 5, class: 'far fa-grin-stars', text: 'Extrêmement utile'}
            ]
        },
        {  question:"Est-ce que vous avez l’impression de mieux comprendre comment vos avantages fonctionnent maintenant ?",
            sequence: 2,
            subject: "BETTER_UNDRSTNDG",
            icons:[
                {score: 1, class: 'far fa-grimace', text:'Non, absolument pas'},
                {score: 2, class: 'far fa-frown', text: 'Non, pas vraiment'},
                {score: 3, class: 'far fa-meh', text: "Peut-être"},
                {score: 4, class: 'far fa-smile', text: 'Oui, un peu'},
                {score: 5, class: 'far fa-grin-stars', text: 'Oui, absolument'}
            ]
        }
      ]
    };
  },  
  props: {
    onQuestionAnswered: {
        type: Function
    },
    clientCode: {
        type: String,
        default: 'MATTR'
    },
  } ,  
  setup() {
    const piniaSurvey = useLoaStore();
    return {
        piniaSurvey
    };
  },
  async mounted() {
    document.body.classList.add('loa_body');

    data.getClientLogoAssetID()
        .then((clientLogoAssetID) => {
            if(clientLogoAssetID > 0) {
                data.getMediaProperty(clientLogoAssetID) 
                    .then((clientLogoAsset) => {
                        if(clientLogoAsset)
                            this.configuration.webPageLogoPath = clientLogoAsset.configuration.src;
                    });
            }
        });

    data.getPageProperties()
        .then((result) => {
            if(result.loaProperties && result.loaProperties.managerAccordionConfiguration && 
                result.loaProperties.managerAccordionConfiguration.accordionCode != '') {
                this.configuration.managerPathConfig = result.loaProperties.managerAccordionConfiguration;
            }

            if(result.emailTemplateMediaPropertyId > 0) {
                data.getMediaProperty(result.emailTemplateMediaPropertyId)
                    .then((emailResult) => {
                        this.configuration.emailTemplate = emailResult.configuration.templateName + '';
                        if(emailResult.configuration.openModalText)
                            this.emailOpenModalText = emailResult.configuration.openModalText;

                        if(emailResult.configuration.logoImageMediaPropertyId > 0) {
                            data.getMediaProperty(emailResult.configuration.logoImageMediaPropertyId)
                                .then((logoResult) => {
                                    this.configuration.emailCorporateLogoPath = logoResult.configuration.src;
                                });
                        }
                    });
            }
        });

    await this.GetClientConstants();

    data.postActivityLog({
        clientCode: this.clientCode,
        logActivityTypeCode: 'LANDING_PAGE'
    });

    document.addEventListener('click', function(event) {
        const target = event.target;
        if (target.tagName === 'A' && target.href) {
          var logObject = {
              url: target.href,
              language: this.language
          };
          data.postActivityLog({
            clientCode: 'MATTR',
            logActivityTypeCode: 'CLICK_LINK',
            data: JSON.stringify(logObject),
          });
        }
    });

    this.page = 0;
  },
  unmounted() {
      document.body.classList.remove('loa_body');
  },
  methods: {
    local_onEndOfSurvey: async function(action) {        
        this.getRelacementStrings();
        
        if(action.subType === 'AccordionInformationPage')
            this.activeInfoPageConfig = action.configuration;
        else if(action.subType === 'SimpleInformationPage')
            this.activeRedirectText = action.configuration.displayText;

        const attributes = this.piniaSurvey.getAttributes;
        data.postActivityLog({
            clientCode: this.clientCode,
            logActivityTypeCode:  'DISPLAY_ACCORDION',
            data: JSON.stringify({attributes: attributes, language: this.language})
        });

        this.page++;
    },
    getRelacementStrings() {
        this.replacements =  this.piniaSurvey.getReplacementStrings; 
        for (const key of Object.keys(this.replacements)) {
            if(key.indexOf('SEMIMONTHLY') >= 0 || key.indexOf('BIWEEKLY') >= 0)
                if(this.replacements[key] % 1 !== 0)
                    this.replacements[key] = Math.round((this.replacements[key] + Number.EPSILON) * 100) / 100;
        }

        var client_replacements;    
    
        if(client_replacements == null) client_replacements = {};
        Object.entries(client_replacements).forEach(
            entry => {
                this.replacements[entry[0]] = entry[1];
            });
    },
    ShowContactModal: async function() {
        data.postActivityLog({
            clientCode: this.clientCode,
            logActivityTypeCode: 'CLICK_LEAVE_MGMT',
            data: JSON.stringify({ language: this.language })
        });
        this.showModal = true;
    },
    closeModal: function() {
        this.showModal = false;
    },
    getStarted: function() {
        data.postActivityLog({
            clientCode: this.clientCode,
            logActivityTypeCode: 'CLICK_GET_STARTED',
            data: JSON.stringify({ language: this.language })
        });
        this.nextPage();
    },
    goToSurvey: function() {
        data.postActivityLog({
            clientCode: this.clientCode,
            logActivityTypeCode: 'CLICK_ELIGIBILITY',
            data: JSON.stringify({ language: this.language })
        });
        this.nextPage();
    },
    nextPage: function() {
        this.page++;
    },
    previousPage: function() {
        this.page--;
    },
    StartOver: function() {
        this.page = 0;
        this.activeInfoPageConfig = null;
        this.activeRedirectText = null;
        this.piniaSurvey.setAttributes(null);
    },
    getLanguageString: function(propName) {
        return this.configuration[this.language.toLowerCase()][propName];
    },
    switchLanguage: function(lang) {
        this.language = lang;
        data.postActivityLog({
            clientCode: this.clientCode,
            logActivityTypeCode: 'CHANGE_LANGUAGE',
            data: JSON.stringify({ language: this.language })
        });
    },
    GetClientConstants: async function() {
        const constants = await data.getClientConstants();

        //get attributes as replacement characters
        var replacementAttributes = await data.getReplacementStringAttributes();
        var replacementStrings = {};

        if(constants != null) {
            constants.forEach(constant => {
                switch(constant.key) {
                    case "LOA_FINE_PRINT_1":
                        this.configuration.english.finePrintColumn1 = translation.getTextForLanguage(constant.valueJson, 'English');
                        break;
                    case "LOA_FINE_PRINT_2":
                        this.configuration.english.finePrintColumn2 = translation.getTextForLanguage(constant.valueJson, 'English');
                        break;
                    case "LOA_FRONT_PAGE_TITLE":
                        this.configuration.english.frontPageTitle = translation.getTextForLanguage(constant.valueJson, 'English');
                        break;
                    case "LOA_FRONT_PAGE_SUBTITLE":
                        this.configuration.english.frontPageSubtitle = translation.getTextForLanguage(constant.valueJson, 'English');
                        break;
                    case "LOA_FRONT_PAGE_TEXT":
                        this.configuration.english.frontPageMainText = translation.getTextForLanguage(constant.valueJson, 'English');
                        break;
                    case "LOA_FINE_PRINT_1_FRENCH":
                        this.configuration.french.finePrintColumn1 = translation.getTextForLanguage(constant.valueJson, 'French');
                        break;
                    case "LOA_FINE_PRINT_2_FRENCH":
                        this.configuration.french.finePrintColumn2 = translation.getTextForLanguage(constant.valueJson, 'French');
                        break;
                    case "LOA_FRONT_PAGE_TITLE_FRENCH":
                        this.configuration.french.frontPageTitle = translation.getTextForLanguage(constant.valueJson, 'French');
                        break;
                    case "LOA_FRONT_PAGE_SUBTITLE_FRENCH":
                        this.configuration.french.frontPageSubtitle = translation.getTextForLanguage(constant.valueJson, 'French');
                        break;
                    case "LOA_FRONT_PAGE_TEXT_FRENCH":
                        this.configuration.french.frontPageMainText = translation.getTextForLanguage(constant.valueJson, 'French');
                        break;
                    case "LOA_CONTACT_MODAL_MESSAGE":
                        this.configuration.contactModalMessage = translation.getTextForLanguage(constant.valueJson, 'English');
                        break;
                    case "LOA_CONTACT_MODAL_TITLE":
                        this.configuration.contactModalTitle = translation.getTextForLanguage(constant.valueJson, 'English');
                        break;
                    case "LOA_CONTACT_MODAL_FOOTER":
                        this.configuration.contactModalFooter = translation.getTextForLanguage(constant.valueJson, 'English');
                        break;
                    case "LOA_CONTACT_MODAL_CUSTOM_CONTACT_INFO":
                        this.configuration.contactModalCustomContactInfo = translation.getTextForLanguage(constant.valueJson, 'English');
                        break;
                    case "HR_PHONE_NUMBER":
                        this.configuration.contactModalPhoneNumber = translation.getTextForLanguage(constant.valueJson, 'English');
                        break;
                    case "HR_EMAIL_ADDRESS":
                        this.configuration.contactModalEmailAddress = translation.getTextForLanguage(constant.valueJson, 'English');
                        break;
                    case "LOA_CONTACTMODAL_BUTTON_TEXT": 
                        this.configuration.english.contactModalButtonText = translation.getTextForLanguage(constant.valueJson, 'English');
                        break;
                    case "LOA_CONTACTMODAL_BUTTON_TEXT_FRENCH": 
                        this.configuration.french.contactModalButtonText = translation.getTextForLanguage(constant.valueJson, 'French');
                        break;
                    case "LOA_SURVEY_CODE":
                        this.surveyCode = translation.getTextForLanguage(constant.valueJson, 'English');
                        break;
                    default:
                        break;
                }            

                if(replacementAttributes.find(search => search.code === constant.key))
                    replacementStrings["%" + constant.key + "%"] = translation.getTextForLanguage(constant.valueJson, 'English');

            });
        }

        replacementStrings['%FILE_STORAGE_URL%'] = await data.getEnvironmentVariable('fileStorageUrl');

        this.piniaSurvey.setReplacementStrings(replacementStrings);

        this.i++;
    }
  },
};
</script>
<style scoped>
    @import url('https://fonts.googleapis.com/css2?family=Caveat&display=swap');
</style>
<style scoped lang="scss" src="@/assets/css/client/MATTR/style.scss"></style>
<style lang="scss" scoped src="@/assets/css/client/MTAB/feedback_modal.scss"></style>
<style scoped> 
    .subheader_empty {
        height: 30px;
    }

    .subheader_with_text {
        height: 80px;   
    }
   
    .subheader_blue {
        background-color: #0981B0;   
    }
    .subheader_green {
        background-color: #3CBF00;
    }

    .subheader_teal {
        background-color: #048468;
    }

    .arrow_down_teal {
        border-top: 12px solid #048468;
    }


    .visible {
        display: unset;
    }

    .hidden {
        display:none;
    }


    @media only screen and (max-width: 962px) {
        .arrow-down {
            margin-left: 30px;
        }
    }

    .flex_across {
        display: flex; 
        flex: 1 1 auto;
    }
</style>
