<template>
    <div class="tableMediaProperty">
        <table>
            <thead v-if="headerRows.length > 0">
                <tr v-for="row in headerRows" :key="row.key" :class="'row-' + row.sequence">
                    <th v-for="cell in row.cells" :key="cell.key" v-html="getCellText(cell)" :class="'cell-' + cell.sequence" 
                        :rowSpan="cell.rowSpan ? cell.rowSpan : '1'" :colSpan="cell.columnSpan ? cell.columnSpan : '1'"></th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="row in bodyRows" :key="row.key" :class="'row-' + row.sequence">
                    <td v-for="cell in row.cells" :key="cell.key" v-html="getCellText(cell)" 
                        :class="[cellClass(cell), 'cell-' + cell.sequence]"
                        :rowSpan="cell.rowSpan ? cell.rowSpan : '1'" :colSpan="cell.columnSpan ? cell.columnSpan : '1'" ></td>
                </tr>
            </tbody>
            <tfoot v-if="footerRows.length > 0">
                <tr v-for="row in footerRows" :key="row.key" :class="'row-' + row.sequence">
                    <td v-for="cell in row.cells" :key="cell.key" v-html="getCellText(cell)" :class="'cell-' + cell.sequence"
                        :rowSpan="cell.rowSpan ? cell.rowSpan : '1'" :colSpan="cell.columnSpan ? cell.columnSpan : '1'"></td>
                </tr>
            </tfoot>
        </table>
    </div>
</template>

<script>
import { replacementStrings } from '@/shared/replacementStrings';
export default {
    name: 'Table',
    props: {
        options: Object,
        replacementStrings: Object,
        language: {
            type: String,
            default: "English"
        }
    },
    data: function() {
        return {
            rowKey: 0,
            cellKey: 0
        }
    },
    computed: {
        headerRows: function() {
            return this.options.rows.filter(row => row.isHeader);
        },
        bodyRows: function() {
            return this.options.rows.filter(row => (!row.isHeader && !row.isFooter));
        },
        footerRows: function() {
            return this.options.rows.filter(row => row.isFooter);
        }
    },
    mounted() {
        let rows = this.options.rows;
        rows.sort((a,b) => {return a.sequence - b.sequence;})
        rows.forEach(row => { 
            row.key = this.rowKey;
            this.rowKey++;
            row.cells.sort((a,b) => {return a.sequence - b.sequence;})
            row.cells.forEach(cell => {
                cell.key = this.cellKey;
                this.cellKey++;
            });
        });
    },
    methods: {
        cellClass: function(cell) {
            if(cell.isTitle)
                return 'tableTitle';
            else if(cell.isTotal)
                return 'tableTotal';
            else
                return '';
        },
        getCellText: function(cell) {
            let returnText = cell.displayTextJson ? cell.displayTextJson.find(tx => tx.language === this.language).text : cell.displayText;

            if(this.replacementStrings)
                returnText = replacementStrings.replaceStringsAllLanguages(returnText, this.replacementStrings);

            return returnText;
        }
    }
}
</script>