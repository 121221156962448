<template>
<div v-if="accordion" class="tiles">
    <div v-for="(section, index) in accordion.sections" :key="section.id" class="tile" :class="['tile' + (index + 1), flippedTiles.includes(section.id) ? 'flipped' : '']" 
            @mouseover="onMouseOver(section)" @click="onClick(section)">
        <div class="tile-inner">
            <div class="tile-front">
                <div v-for="header in section.headers" :key="header.id" class="tile-front-header" v-html="getText(header.minimizedText)">
                </div>
                <div v-for="detail in getFrontDetails(section)" :key="detail.id" :class="detail.cssClass">
                    <img v-if="detail.mediaProperty" :src="detail.mediaProperty.src" />
                    <div v-if="!detail.mediaProperty"  v-html="getText(detail.displayText)"></div>            
                </div>
            </div>
            <div class="tile-back">
                <div v-for="header in section.headers" :key="header.id" class="tile-back-header">
                    <div v-html="getText(header.expandedText)"></div>            
                </div>
                <div v-for="detail in getBackDetails(section)" :key="detail.id" :class="detail.cssClass">
                    <img v-if="detail.mediaProperty" :src="detail.mediaProperty.src" />
                    <div v-if="!detail.mediaProperty"  v-html="getText(detail.displayText)"></div>            
                </div>
            </div>
        </div>
    </div>
</div>
</template>
<script> 
import { data } from '@/shared';
import { useLoaStore } from "@/stores/loa";
import { audio } from '@/shared/audio';
import { replacementStrings } from '@/shared/replacementStrings';
import { translation } from '@/shared/translation';

export default {
  name: "HtmlTiles",
  data() {
    return {
      storageBaseUrl: null,
      attributes: null,
      accordion: null,
      flippedTiles: []
    }
  },
  setup() {
    const piniaSurvey = useLoaStore();
    return { piniaSurvey };
  },
  props: {
    configuration: {
        type: Object, 
        required: true
    },
    onChangeTile: {
        type: Function,
        required: true
    },
    language: String,
    replacements: Object
  },  
  mounted() {
    this.getAccordion();
  },
  watch: {
    configuration: function() {
        this.getAccordion();
    }
  },
  methods: {
    onMouseOver: async function(section) {
        if(this.configuration.changeAction == 'onHover')
            this.flipTile(section);

    },
    onClick: async function(section) {
        if(this.configuration.changeAction === 'onClick') {
            this.flipTile(section);
        }  
    },
    flipTile:async function(section) {
        if(!this.flippedTiles.includes(section.id)) {
            this.flippedTiles.push(section.id);
        
            if(section.tracks && section.tracks.length > 0) 
                this.onChangeTile(section.tracks);
        }
    },
    getAccordion: async function() {
        this.attributes = this.piniaSurvey.getAttributes;
        this.storageBaseUrl = await data.getEnvironmentVariable(data.enumEnvironmentVariableFields.fileStorageUrl);
        var accordion = await data.getAccordionByID(this.configuration.tileDataID, this.attributes);

        accordion.sections.sort((a, b) => {
            return a.sequence - b.sequence;
        });

        const replacementKeys = this.replacements ? Object.keys(this.replacements) : null;
        if(replacementKeys && accordion.headers) {
            for (let i = 0; i < accordion.headers.length; i++) 
                accordion.headers[i].displayTextJson = replacementStrings.replaceStringsAllLanguages(accordion.headers[i].displayTextJson, this.replacements);

            var section;
            for(let i = 0; i < accordion.sections.length; i++) {
                section = accordion.sections[i];
                section.headers.forEach(header => {
                    header.minimizedTextJson = replacementStrings.replaceStringsAllLanguages(header.minimizedTextJson, this.replacements);
                    header.expandedTextJson = replacementStrings.replaceStringsAllLanguages(header.minimizedTextJson, this.replacements);
                });
                
                for(var d = 0; d < section.details.length; d++)
                    section.details[d].displayTextJson = replacementStrings.replaceStringsAllLanguages(section.details[d].displayTextJson, this.replacements);                   
            }
        }

        this.loadAudioTracks(accordion);
        this.accordion = accordion;
    },
    getFrontDetails(section) {
        return this.getDetails(section, 'front');
    },
    getBackDetails(section) {
        return this.getDetails(section, 'back');
    },
    getDetails(section, frontOrBack)  {
        const modVal = frontOrBack == 'front' ? 1 : 0;
        return section.details.filter(search => search.sequence % 2 === modVal);
    },
    async loadAudioTracks(accordion) {
        for(var j = 0; j < accordion.sections.length; j++)  {
            if(accordion.sections[j] != null) {
                accordion.sections[j].tracks = await audio.loadAudioTracks(accordion.actions.filter(act => act.accordionSectionID === accordion.sections[j].id));
            }
        }
    },
    getText(text) {
        return Array.isArray(text) ? translation.getTextForLanguage(text, this.language) : text;
    }
  }
}

</script>
