import { translation } from '@/shared/translation';

const replaceStringsOfLanguage = function(jsonArray, language, replacementStrings) {
    // This function replaces all instances of ReplacementStrings in the text property of the specified language
    // Currently not being used anywhere
    return jsonArray.map(ms => {
        if(ms.language === language) {
            for (const replacementKey in replacementStrings) {
                if(Array.isArray(replacementStrings[replacementKey]))
                    ms.text = ms.text.replaceAll(replacementKey, translation.getTextForLanguage(replacementStrings[replacementKey], language));
                else
                    ms.text = ms.text.replaceAll(replacementKey, replacementStrings[replacementKey]);
            }
        }
        return ms;
    });
}

const replaceStringsAllLanguages = function(text, replacementStrings) {
    // The text parameter can be a string or an array of {text: 'text', language: 'language'} objects
    // If a string, all instances of ReplacementKeys are replaced in the string
    if(!Array.isArray(text)) {
        for (const replacementKey in replacementStrings)
            text = text.replaceAll(replacementKey, replacementStrings[replacementKey]);
        return text;
    } 
    else 
    // If text parameter is an array, all instances of ReplacementKeys in all Text properties in the array are replaced
        return text.map(ms => {
            for (const replacementKey in replacementStrings) {
                if(Array.isArray(replacementStrings[replacementKey])) {
                    let replaceText = translation.getTextForLanguage(replacementStrings[replacementKey], ms.language);
                    if(replacementStrings[replaceText])
                        replaceText = replacementStrings[replaceText];
                    ms.text = ms.text.replaceAll(replacementKey, replaceText);
                }
                else
                    ms.text = ms.text.replaceAll(replacementKey, replacementStrings[replacementKey]);
            }
            return ms;
        });
}

export const replacementStrings = {
    replaceStringsOfLanguage,
    replaceStringsAllLanguages
}