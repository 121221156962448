import { defineStore } from "pinia";

// Utility function for safely parsing JSON
function safeParse(jsonString, defaultValue = {}) {
    if(jsonString === "null" || jsonString === undefined)
        jsonString = null;

    try {
      return jsonString != null ? JSON.parse(jsonString) : defaultValue;
    } catch (e) {
      console.error("Error parsing JSON", e);
      return defaultValue; // return a default value in case of error
    }
  }


export const useLoaStore = defineStore ('loa', {
    state: () => ({
        // Contains key-value pairs of Attribute Code and Answer Value
        attributes: safeParse(localStorage.getItem('attributes') || '{}'),
        replacementStrings: safeParse(localStorage.getItem('replacementStrings') || '{}')
    }),
    getters: {
        getAttributes(state) {
            return state.attributes;
        },
        getReplacementStrings(state) {
            return state.replacementStrings;
        }

    },
    actions: {
        setAttributes(attributeData) {        
            this.attributes = safeParse(typeof attributeData == 'string' ? attributeData : JSON.stringify(attributeData));
            localStorage.setItem('attributes', JSON.stringify(this.attributes)); 
        },
        setAttribute(fieldName, value) {
            this.attributes[fieldName] = value;
            localStorage.setItem('attributes', JSON.stringify(this.attributes)); 
        },
        setReplacementStrings(data) {
            this.replacementStrings = safeParse(typeof data == 'string' ? data : JSON.stringify(data));
            localStorage.setItem('replacementStrings', JSON.stringify(this.replacementStrings));
        },
        setReplacementString(fieldName, value) {
            this.replacementStrings[fieldName] = value;
            localStorage.setItem('replacementStrings', JSON.stringify(this.replacementStrings)); 
        },
    }
})