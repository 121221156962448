<template>
    <div>
        <Survey code="JV_Test_Su" sectionTitle="Eligibility" :endSurveyFunction="finishSurvey" goBackButtonText="Go Back"></Survey>
    </div>
</template>

<script>
import Survey from '@/components/shared/Survey/Survey3.vue';
import { useLoaStore } from "@/stores/loa";

export default {
    name: 'TestSurvey',
    components: { Survey },
    methods: {
        finishSurvey: function() {
            alert('Finished Survey');
        }
    },
    setup() {
        const piniaSurvey = useLoaStore();
        return {
            piniaSurvey
        };
    }
}
</script>

<style lang="scss" scoped src="@/assets/css/client/loa/style.scss"></style>